






















































import { Component, Vue, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import StudentBottomPanel from '@/components/organisms/v3/StudentBottomPanel.vue'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
    LabelBase,
    StudentBottomPanel,
  },
})
export default class CurriculumSUnitsClassCategory extends Mixins(DrillWebApi, DrillTransition) {
  private subjectCode = this.$route.params.subjectCode
  private curriculumSUnitId = Number(this.$route.params.curriculumSUnitId)

  // FIXME: GDLS-1923 AI学習モード限定になっているので、必要になった場合は修正
  // AI学習関連の定義値
  private studyLogicType = 'AI'
  // private classModeCode = 'AI'

  private classMode = this.$route.params.classMode

  private curriculumSUnitName = ''
  private classPolicy: { [key: string]: boolean } = {}
  private history: { [key: string]: any } = {}

  // 小項目に対して学習内容が実施可能かを保持する
  private actionEnabled: { [key: string]: boolean } = {}

  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas(): Promise<void> {
    await Vue.prototype.$http.httpWithToken
      .get('/v3/curriculumSUnitCategories', {
        params: {
          curriculumSUnitId: this.curriculumSUnitId,
          subjectCode: this.subjectCode,
          classMode: this.classMode,
        },
      })
      .then((res: any) => {
        this.$store.commit('drillsV3/setTitleResult', res.data.curriculumSUnitName)

        this.curriculumSUnitName = res.data.curriculumSUnitName
        this.classPolicy = res.data.classPolicy
        this.history = res.data.history
        // FIXME: 旧APIの場合は権限と有効な操作に同値を設定する(暫定)
        this.actionEnabled = res.data.actionEnabled
      })
  }

  private get introductionUrl(): string {
    return `/student/v3/curriculum-s-units/${this.curriculumSUnitId}/introduction-video/${this.classMode}`
  }

  private get unitListUrl(): string {
    return `/student/v3/curriculum-s-units/${this.classMode}/${this.subjectCode}`
  }

  private get rectangleStyleSet(): { [key: string]: string } {
    let listSize = 0
    if (this.classPolicy['EZ']) listSize += 1
    if (this.classPolicy['RK']) listSize += 1
    if (this.classPolicy['EN']) listSize += 1
    let height = '0'
    if (listSize === 3) {
      height = '180px'
    } else if (listSize === 2) {
      height = '90px'
    }
    return {
      '--rectangle-height': height,
    }
  }

  /**
   * 演習開始
   */
  private async startDrill(classCategoryCode: string) {
    Vue.prototype.$loading.start()
    // ドリル開始APIを実行
    if (classCategoryCode == 'RK') {
      this.$store.commit('drills/setIsClassCategoryCodeRK', true)
    } else {
      this.$store.commit('drills/setIsClassCategoryCodeRK', false)
    }
    const drill = await this.startDrillApi(this.studyLogicType, this.classMode, classCategoryCode, {
      curriculumSUnitId: this.curriculumSUnitId,
    })

    Vue.prototype.$loading.complete()
    // 演習画面へ遷移
    //TODO make cr phase4
    if(this.subjectCode === 'su') {
      this.$router.push({
        path: this.urlDrillMathWithId(drill.resultDrillId, drill.currentPage),
      })
      return
    }
    this.$router.push({
      path: this.urlDrillWithId(drill.resultDrillId, drill.currentPage),
    })
  }

  /**
   * 解説動画画面に遷移する
   */
  public transferIntroductionVideo() {
    this.$router.push({
      path: this.introductionUrl,
      query: {
        historyBackUrl: encodeURIComponent(this.$route.fullPath),
      },
    })
  }
}
